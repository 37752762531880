var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VExpansionPanels',{staticClass:"py-3",model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',{attrs:{"expand-icon":"fal fa-chevron-down"}},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(" Новое приложение ")])]),_c('VExpansionPanelContent',[_c('VForm',{ref:"form",attrs:{"disabled":_vm.entity.loading}},[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',{attrs:{"md":"6"}},[_c('div',[_c('strong',[_vm._v("Имя")])]),_c('VTextField',{model:{value:(_vm.entity.name),callback:function ($$v) {_vm.$set(_vm.entity, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"entity.name"}})],1),_c('VCol',{attrs:{"md":"6"}},[_c('div',[_c('strong',[_vm._v("Bundle name")])]),_c('VTextField',{model:{value:(_vm.entity.bundleName),callback:function ($$v) {_vm.$set(_vm.entity, "bundleName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"entity.bundleName"}})],1)],1),_c('VRow',[_c('VCol',{attrs:{"md":"4"}},[_c('VBtn',{staticClass:"mr-3",attrs:{"large":"","depressed":"","color":"primary","loading":_vm.entity.loading},on:{"click":_vm.save}},[_vm._v(" Создать приложение ")]),_c('VBtn',{attrs:{"large":"","text":"","depressed":""},on:{"click":_vm.cancel}},[_vm._v(" Отмена ")])],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('VRow',[_c('VCol',{attrs:{"cols":"12"}},[_c('AppsTable',{attrs:{"apps":_vm.entitiesList,"loading":_vm.loading,"disable-pagination":false,"hide-default-footer":false,"items-per-page":_vm.pagination.limit,"page":_vm.pagination.page,"server-items-length":_vm.pagination.total,"footer-props":{
          itemsPerPageOptions : _vm.pagination.itemsPerPageOptions,
          showFirstLastPage : true
        }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('TTTextField',{attrs:{"placeholder":"Поиск","append-icon":"$search"},model:{value:(_vm.searchApps),callback:function ($$v) {_vm.searchApps=$$v},expression:"searchApps"}})]},proxy:true},{key:"rowActions",fn:function(ref){
        var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"black"},on:{"click":function($event){return _vm.openEditDialog(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Изменить Bundle name")])]),(item.active === false || !item.active)?_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green"},on:{"click":function($event){return _vm.activateApp(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-unlock ")])],1)]}}],null,true)},[_c('span',[_vm._v("Активировать")])]):_vm._e(),(item.active === true)?_c('VTooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"red"},on:{"click":function($event){return _vm.deactivateApp(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-lock ")])],1)]}}],null,true)},[_c('span',[_vm._v("Деактивировать")])]):_vm._e()]}}])}),_c('VDialog',{attrs:{"width":"600px"},on:{"input":function($event){return _vm.resetEntity(_vm.entityEdited)}},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('VCard',[_c('VCardTitle',[_vm._v("Редактировать")]),_c('VCardText',[_c('VRow',[_c('VCol',{attrs:{"cols":"6"}},[_c('VTextField',{attrs:{"label":"Bundle name"},model:{value:(_vm.entityEdited.bundleName),callback:function ($$v) {_vm.$set(_vm.entityEdited, "bundleName", $$v)},expression:"entityEdited.bundleName"}})],1)],1)],1),_c('VCardActions',[_c('VBtn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.changeBundleName(_vm.entityEdited)}}},[_vm._v(" Сохранить ")]),_c('VBtn',{attrs:{"color":"error"},on:{"click":function($event){_vm.editDialog = false; _vm.resetEntity(_vm.entityEdited)}}},[_vm._v(" Отменить ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }