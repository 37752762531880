<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="apps"
    :loading="loading"
    v-bind="{...$attrs, ...$props}"
    v-on="$listeners"
  >
    <template #top>
      <VRow class="ma-0">
        <VCol cols="6">
          <VToolbarTitle>Приложения</VToolbarTitle>
        </VCol>
        <VCol cols="6">
          <slot name="topActions" />
        </VCol>
      </VRow>
    </template>

    <template #item.active="{item}">
      <VIcon :color="item.active ? 'success' : 'warning'">
        fal {{ item.active ? 'fa-check-circle' : 'fa-times-circle' }}
      </VIcon>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'AppsTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    apps: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Bundle name',
          align: 'left',
          sortable: false,
          value: 'bundleName',
        },
        {
          text: 'Активное',
          align: 'left',
          sortable: false,
          value: 'active',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
