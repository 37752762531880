<template>
  <TTView>
    <VRow>
      <VCol>
        <VExpansionPanels
          v-model="panel"
          class="py-3"
        >
          <VExpansionPanel>
            <VExpansionPanelHeader
              expand-icon="fal fa-chevron-down"
            >
              <h2 class="font-weight-bold">
                Новое приложение
              </h2>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <VForm
                ref="form"
                :disabled="entity.loading"
              >
                <VContainer fluid>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Имя</strong>
                      </div>
                      <VTextField
                        v-model.trim="entity.name"
                      />
                    </VCol>
                    <VCol md="6">
                      <div>
                        <strong>Bundle name</strong>
                      </div>
                      <VTextField
                        v-model.trim="entity.bundleName"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol md="4">
                      <VBtn
                        large
                        depressed
                        color="primary"
                        class="mr-3"
                        :loading="entity.loading"
                        @click="save"
                      >
                        Создать приложение
                      </VBtn>
                      <VBtn
                        large
                        text
                        depressed
                        @click="cancel"
                      >
                        Отмена
                      </VBtn>
                    </VCol>
                  </VRow>
                </VContainer>
              </VForm>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12">
        <AppsTable
          :apps="entitiesList"
          :loading="loading"
          :disable-pagination="false"
          :hide-default-footer="false"
          :items-per-page.sync="pagination.limit"
          :page.sync="pagination.page"
          :server-items-length="pagination.total"
          :footer-props="{
            itemsPerPageOptions : pagination.itemsPerPageOptions,
            showFirstLastPage : true
          }"
        >
          <template #topActions>
            <TTTextField
              v-model="searchApps"
              placeholder="Поиск"
              append-icon="$search"
            />
          </template>
          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  class="mx-1"
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  @click="openEditDialog(item)"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>
              <span>Изменить Bundle name</span>
            </VTooltip>

            <VTooltip
              v-if="item.active === false || !item.active"
              right
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="green"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="activateApp(item)"
                >
                  <VIcon small>
                    fal fa-unlock
                  </VIcon>
                </VBtn>
              </template>

              <span>Активировать</span>
            </VTooltip>

            <VTooltip
              v-if="item.active === true"
              right
            >
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="deactivateApp(item)"
                >
                  <VIcon small>
                    fal fa-lock
                  </VIcon>
                </VBtn>
              </template>

              <span>Деактивировать</span>
            </VTooltip>
          </template>
        </AppsTable>
        <VDialog
          v-model="editDialog"
          width="600px"
          @input="resetEntity(entityEdited)"
        >
          <VCard>
            <VCardTitle>Редактировать</VCardTitle>
            <VCardText>
              <VRow>
                <VCol cols="6">
                  <VTextField
                    v-model="entityEdited.bundleName"
                    label="Bundle name"
                  />
                </VCol>
              </VRow>
            </VCardText>
            <VCardActions>
              <VBtn
                color="primary"
                @click="changeBundleName(entityEdited)"
              >
                Сохранить
              </VBtn>
              <VBtn
                color="error"
                @click="editDialog = false; resetEntity(entityEdited)"
              >
                Отменить
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AppsTable from '../../../components/license/apps/AppsTable.vue';

export default {
  name: 'AppList',

  components: {
    AppsTable,
  },

  data() {
    return {
      entity: {
        name: '',
        bundleName: '',
        loading: false,
      },
      entitiesList: [],
      entityEdited: {
        id: null,
        bundleName: '',
      },
      searchApps: '',
      searchTimer: null,
      editDialog: false,
      panel: undefined,
      accounts: [],
      loading: false,
      pagination: {
        page: 1,
        limit: 10,
        total: 0,
        itemsPerPageOptions: [10, 50, 100],
      },
    };
  },

  watch: {
    searchApps: {
      handler(query) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          this.fetch({ query, page: 1 });
        }, 500);
      },
    },
    'pagination.page': {
      handler(page) {
        this.fetch({ page });
      },
    },
    'pagination.limit': {
      handler(limit) {
        // TODO: убрать этот хак после выхода таски с поиском
        // сервер не понимает -1, передаем "максимальное" значение
        this.fetch({ limit: limit !== -1 ? limit : 100000 });
      },
    },
  },

  async created() {
    await this.fetch({ page: 1 });
  },

  methods: {
    async fetch({ page = this.pagination.page, limit = this.pagination.limit, query = this.searchApps }) {
      const payload = query.length > 2 ? { page, limit, query } : { page, limit };
      try {
        this.loading = true;
        const [appsResponse] = await Promise.all([
          this.$di.api.License.indexAppWithPagination(payload),
        ]);

        this.entitiesList = appsResponse.apps || [];
        this.pagination.total = appsResponse.total || 0;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$refs.form.reset();
    },

    removeLabel(name) {
      this.entity.labels = this.entity.labels.filter((i) => i.name !== name);
    },

    save() {
      this.entity.loading = true;
      this.$di.api.License.createApp({ app: this.entity })
        .then(() => this.fetch({ page: 1 }))
        .then(() => this.resetEntity(this.entity))
        .catch(this.$di.notify.errorHandler)
        .finally(() => { this.entity.loading = false; });
    },

    async activateApp(item) {
      try {
        this.loading = true;
        const data = { id: item.id };
        await this.$di.api.License.activateApp(data);
        this.$di.notify.snackSuccess('Успешно активировано');
        await this.fetch({ page: 1 });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    async deactivateApp(item) {
      try {
        this.loading = true;
        const data = { id: item.id };
        await this.$di.api.License.deactivateApp(data);
        this.$di.notify.snackSuccess('Деактивировано');
        await this.fetch({ page: 1 });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
      }
    },

    async changeBundleName(item) {
      try {
        this.loading = true;
        await this.$di.api.License.changeBundleName(item);
        this.$di.notify.snackSuccess('Bundle name изменен');
        this.editDialog = false;
        this.entitiesList.find((i) => i.id === item.id).bundleName = item.bundleName;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    openEditDialog({ id }) {
      const item = this.entitiesList.find((i) => i.id === id);
      this.entityEdited.id = item.id;
      this.entityEdited.bundleName = item.bundleName;
      this.editDialog = true;
    },

    resetEntity(entity) {
      Object.assign(entity, {
        id: null,
        name: '',
        bundleName: '',
        active: false,
      });
    },
  },
};
</script>
